import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  @Input() text = `The page you're looking for could not be found!`

  constructor() {}

  ngOnInit() {}
}
