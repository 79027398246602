import { Observable } from 'rxjs'
import { NgModule, PLATFORM_ID } from '@angular/core'
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { environment } from 'src/environments/environment'
import { OidcSecurityService } from 'angular-auth-oidc-client'
import { config } from './config'

const toPromise = (obs: Observable<any>): Promise<any> => {
  return new Promise((resolve, reject) => {
    obs.subscribe((res) => {
      return resolve(res)
    })
  })
}

export function createApollo(
  httpLink: HttpLink,
  oidcSecurityService: OidcSecurityService
) {
  const uri = `${config.apiBase}/graphql`

  const http = httpLink.create({
    uri
  })

  const auth = setContext(async (_, { headers }) => {
    const oidcToken: string = await toPromise(
      oidcSecurityService.getAccessToken()
    )

    const isAuthenticated: boolean = await toPromise(
      oidcSecurityService.isAuthenticated()
    )

    if (isAuthenticated) {
      return {
        headers: { Authorization: `Bearer ${oidcToken}` }
      }
    }

    return {}
  })

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    }
  }

  return {
    link: auth.concat(http),
    cache: new InMemoryCache(),
    name: 'pages-client',
    version: environment.sentryRelease,
    defaultOptions
  }
}

@NgModule({
  exports: [],
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, OidcSecurityService, PLATFORM_ID]
    }
  ]
})
export class GraphQLModule {}
