import { config } from 'src/app/config'
import { environment } from 'src/environments/environment'
import { ConfigService } from './config.service'
import { Injectable, EventEmitter } from '@angular/core'
import KeenTracking from 'keen-tracking'
import { Router, NavigationEnd } from '@angular/router'

const { helpers, utils } = KeenTracking

export interface AnalyticsContext {
  orgId?: string
  eventId?: string
  userId?: string
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  public orgId: string
  public eventId: string
  public userId: string
  public uuid: string
  public initialReferrer: string

  public client: KeenTracking
  private cookie: any

  private timer: any
  private isReady = false
  private onReady: EventEmitter<boolean> = new EventEmitter()
  private cookieDomain: any = {}

  constructor(private router: Router, private configSvc: ConfigService) {
    this.initKeen()
    this.recordRouterNavigationEvents()

    this.onReady.subscribe(() => {
      this.isReady = true
    })
  }

  private log(message?: any, ...optionalParams: any[]) {
    // console.log('ANALYTICS: ' + message, ...optionalParams)
  }

  private initKeen() {
    this.log('initKeen')
    const { projectId, writeKey } = this.configSvc.config.keen

    this.client = new KeenTracking({
      projectId,
      writeKey,
      requestType: 'beaconAPI',
      host: 'analytics.event1.io'
    })

    if (!environment.production) {
      KeenTracking.enabled = false
      KeenTracking.debug = true

      this.client.on('recordEvent', (event, data) => {
        this.log(event, data)
      })
    }

    this.timer = utils.timer()
    this.timer.start()

    this.initCookie()

    // Batch-record events every 5s
    this.client.queueInterval(5)

    const browserProfile = helpers.getBrowserProfile()

    this.client.extendEvents(() => {
      const data = {
        org_id: this.orgId,
        event_id: this.eventId,

        tracked_by: 'pages',
        local_time_full: new Date().toISOString(),
        user: {
          uuid: this.uuid,
          user_id: this.userId
        },
        page: {
          title: document ? document.title : null,
          description: browserProfile.description,
          url: window.location.href,
          time_on_page: this.timer.value()
        },

        ip_address: '${keen.ip}',
        geo: {
          /* Enriched */
        },

        user_agent: '${keen.user_agent}',
        tech: {
          profile: browserProfile
          /* Enriched */
        },

        url: {
          full: window ? window.location.href : '',
          info: {
            /* Enriched */
          }
        },

        referrer: {
          initial: this.initialReferrer,
          full: document ? document.referrer : '',
          info: {
            /* Enriched */
          }
        },

        time: {
          local: {
            /* Enriched */
          },
          utc: {
            /* Enriched */
          }
        },

        device: {
          uuid: this.uuid,
          platform: {
            name: 'web'
          }
        },

        keen: {
          timestamp: new Date().toISOString(),
          addons: [
            {
              name: 'keen:ua_parser',
              input: {
                ua_string: 'user_agent'
              },
              output: 'tech'
            },
            {
              name: 'keen:ip_to_geo',
              input: {
                ip: 'ip_address'
              },
              output: 'geo'
            },
            {
              name: 'keen:url_parser',
              input: {
                url: 'url.full'
              },
              output: 'url.info'
            },
            {
              name: 'keen:url_parser',
              input: {
                url: 'referrer.full'
              },
              output: 'referrer.info'
            },
            {
              name: 'keen:date_time_parser',
              input: {
                date_time: 'keen.timestamp'
              },
              output: 'time.utc'
            },
            {
              name: 'keen:date_time_parser',
              input: {
                date_time: 'local_time_full'
              },
              output: 'time.local'
            }
          ]
        }
      }

      this.log('extendEvents', data)

      return data
    })
  }

  private recordRouterNavigationEvents() {
    this.log('recordRouterNavigationEvents')
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.track('pageView', {
          path: event.url,
          type: 'pages'
        })

        const gaPayload = {
          page_path: event.urlAfterRedirects,
          page_location: window.location.href
        }
        window.gtag('config', environment.gaId, gaPayload)
        if (config?.gaId) {
          window.gtag('config', config.gaId, gaPayload)
        }
      }
    })
  }

  public track(name: string, data?: any) {
    this.ready()
      .then(() => {
        this.log('track', name, data)
        this.client.recordEvent(name, data)
      })
      .catch(this.log)
  }

  public setContext(context?: AnalyticsContext) {
    this.log('setContext', context)
    if (context && context.orgId !== undefined) {
      this.orgId = context.orgId
    }
    if (context && context.eventId !== undefined) {
      this.eventId = context.eventId
    }
    if (context && context.userId !== undefined) {
      this.userId = context.userId
    }

    this.onReady.emit(true)
  }

  public resetContext() {
    this.log('resetContext')
    this.setContext({ orgId: null, eventId: null, userId: null })
  }

  private initCookie() {
    this.cookie = new utils.cookie('e1')

    const domainName = helpers.getDomainName(window.location.hostname)

    if (domainName && domainName !== 'localhost') {
      this.cookieDomain = {
        domain: '.' + domainName
      }
    }

    this.uuid = this.cookie.get('uuid')
    if (!this.uuid) {
      this.uuid = helpers.getUniqueId()
      this.cookie.set('uuid', this.uuid, this.cookieDomain)
    }

    this.initialReferrer = this.cookie.get('initialReferrer')
    if (!this.initialReferrer) {
      this.initialReferrer = (document && document.referrer) || undefined
      this.cookie.set(
        'initialReferrer',
        this.initialReferrer,
        this.cookieDomain
      )
    }
  }

  private ready(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isReady) {
        return resolve()
      }

      this.onReady.subscribe(() => {
        return resolve()
      })
    })
  }
}
