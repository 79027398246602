interface Config {
  canonicalDomain: string
  canonicalBase: string
  org: string
  gaId?: string
  apiBase: string
  clientId: string
}

declare global {
  interface Window {
    e1PageConfig: Config
  }
}

export const config: Config = window.e1PageConfig
