import { environment } from 'src/environments/environment'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AnalyticsService } from './services/analytics.service'
import { ConfigService, ISystemConfig } from './services/config.service'
import { Component, OnInit } from '@angular/core'
import { OidcSecurityService } from 'angular-auth-oidc-client'
import { AuthService } from './auth/auth.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public devSettings: any
  public showDevMode = false

  // import the config service so that it initializes early
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private config: ConfigService,
    private auth: AuthService,
    private modalService: NgbModal,
    private analytics: AnalyticsService
  ) {
    this.showDevMode = !environment.production
  }

  ngOnInit() {
    this.config.remoteFetched.subscribe((config: ISystemConfig) => {
      this.showDevMode = config.environment === 'production' ? false : true
    })

    this.oidcSecurityService.checkAuth().subscribe((auth) => {
      if (auth.isAuthenticated) {
        // do the post login redirect if needed and the user is authenticated
        this.auth.doLoginRedirect()
      }
    })
  }

  public openDevSettings(content: any) {
    this.devSettings = {
      org: localStorage.getItem('dev_e1_org') || 'filiosoft'
    }

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-dev-settings-title' })
      .result.then(
        (result) => {
          if (result === 'save') {
            this.saveSettings()
          }
        },
        (reason) => {}
      )
  }

  private saveSettings() {
    if (this.devSettings.org !== 'filiosoft') {
      localStorage.setItem('dev_e1_org', this.devSettings.org?.toLowerCase())
    } else {
      localStorage.removeItem('dev_e1_org')
    }

    location.reload()
  }
}
