import { RouterModule } from '@angular/router'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FooterComponent } from './footer/footer.component'
import { MomentPipe } from './pipes/moment.pipe'
import { NotFoundComponent } from './not-found/not-found.component'
import { TruncatePipe } from './pipes/truncate.pipe'
import { LoaderComponent } from './loader/loader.component'
import { SortDatePipe } from './pipes/sort-date.pipe'
import { TextFieldComponent } from './custom-forms/fields/text-field/text-field.component'
import { CustomFormComponent } from './custom-forms/custom-form/custom-form.component'
import { SingleChoiceFieldComponent } from './custom-forms/fields/single-choice-field/single-choice-field.component'
import { MultiChoiceFieldComponent } from './custom-forms/fields/multi-choice-field/multi-choice-field.component'
import { TextFieldMultiComponent } from './custom-forms/fields/text-field-multi/text-field-multi.component'
import { HeaderComponent } from './header/header.component';
import { AddressFormComponent } from './address-form/address-form.component'

@NgModule({
  declarations: [
    FooterComponent,
    MomentPipe,
    NotFoundComponent,
    TruncatePipe,
    LoaderComponent,
    SortDatePipe,
    TextFieldComponent,
    CustomFormComponent,
    SingleChoiceFieldComponent,
    MultiChoiceFieldComponent,
    HeaderComponent,
    AddressFormComponent,
    TextFieldMultiComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    FormsModule
  ],
  exports: [
    FooterComponent,
    MomentPipe,
    NotFoundComponent,
    TruncatePipe,
    LoaderComponent,
    SortDatePipe,
    HeaderComponent,
    AddressFormComponent,
    CustomFormComponent
  ]
})
export class SharedModule {}
