import { ThanksModalComponent } from './event/register/thanks-modal/thanks-modal.component'
import { PaymentInfoComponent } from './event/register/register-modal/payment-info/payment-info.component'
import { TicketInfoComponent } from './event/register/register-modal/ticket-info/ticket-info.component'
import { TicketFormComponent } from './event/register/register-modal/ticket-info/ticket-form/ticket-form.component'
import { PipesModule } from './utils/pipes/pipes.module'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { ShareModalComponent } from './shared/share-modal/share-modal.component'
import { GraphQLModule } from './graphql.module'
import { BrowserModule } from '@angular/platform-browser'
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core'
import {
  AuthModule,
  LogLevel,
  OpenIdConfiguration
} from 'angular-auth-oidc-client'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { ClipboardModule } from 'ngx-clipboard'
import { RegisterModalComponent } from './event/register/register-modal/register-modal.component'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { Router } from '@angular/router'
import * as Sentry from '@sentry/angular-ivy'
import { config } from './config'

declare global {
  interface Window {
    gtag: any
    FS: any
    fathom: any
  }
}

const oidcConfig: OpenIdConfiguration = {
  authority: `${config.apiBase}/oauth`,
  redirectUrl: `${window.location.origin}/_/callback`,
  postLogoutRedirectUri: `${window.location.origin}/_/logout`,
  clientId: config.clientId,
  scope: 'openid profile email',
  responseType: 'code',
  silentRenew: false,
  logLevel: LogLevel.Warn
}

@NgModule({
  declarations: [
    AppComponent,
    ShareModalComponent,
    RegisterModalComponent,
    TicketInfoComponent,
    PaymentInfoComponent,
    ThanksModalComponent,
    TicketFormComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    GraphQLModule,
    NgbModule,
    FontAwesomeModule,
    ClipboardModule,
    ReactiveFormsModule,
    FormsModule,
    AuthModule.forRoot({ config: oidcConfig }),
    PipesModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
