import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Apollo } from 'apollo-angular'
import gql from 'graphql-tag'
import * as Sentry from '@sentry/angular-ivy'
import { AnalyticsService } from '../services/analytics.service'
import {
  AuthenticatedResult,
  OidcSecurityService
} from 'angular-auth-oidc-client'
import { map } from 'rxjs/operators'
import { Location } from '@angular/common'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public AUTH_REDIRECT_KEY = 'pages_auth_redirect'
  public isAuthenticated = false

  constructor(
    private apollo: Apollo,
    private analytics: AnalyticsService,
    private oidcSecurityService: OidcSecurityService,
    private location: Location,
    private router: Router
  ) {
    this.oidcSecurityService.isAuthenticated$.subscribe(
      (result: AuthenticatedResult) => {
        this.isAuthenticated = result.isAuthenticated
      }
    )
  }

  isLoggedIn(): boolean {
    return this.isAuthenticated
  }

  async logout() {
    this.oidcSecurityService.logoffLocal()
    await this.apollo.getClient().resetStore()
  }

  async identify() {
    try {
      const user = await this.me().toPromise()

      if (user) {
        const name =
          user.firstName && user.lastName
            ? `${user.firstName || ''} ${user.lastName || ''}`
            : null

        this.analytics.setContext({
          userId: user.id
        })

        Sentry.configureScope((scope) => {
          scope.setUser({
            id: user.id,
            email: user.email,
            name
          })
        })

        if (window.FS) {
          window.FS.identify(user.id, {
            displayName: name,
            email: user.email
          })
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  me() {
    return this.apollo
      .query<any>({
        query: gql`
          query Pages_GetMe {
            me {
              id
              firstName
              lastName
              email
              picture
              created
            }
          }
        `
      })
      .pipe(
        map((data) => {
          return data?.data?.me
        })
      )
  }

  public async setRedirectUrl(commands: any) {
    localStorage.setItem(this.AUTH_REDIRECT_KEY, commands)
  }

  public async doLoginRedirect() {
    try {
      const redirectUrl = localStorage.getItem(this.AUTH_REDIRECT_KEY)

      if (!redirectUrl) {
        return null
      }

      await this.router.navigateByUrl(redirectUrl)

      localStorage.removeItem(this.AUTH_REDIRECT_KEY)
    } catch (err) {
      console.error(err)
    }
  }

  public authorize() {
    this.setRedirectUrl(this.location.path())
    this.oidcSecurityService.authorize()
  }
}
