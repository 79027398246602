<div class="container mt-5">
  <div class="row">
    <div class="col text-center">
      <img
        src="https://cdn.event1.io/graphics/logo/wm-cropped.png"
        alt="eventOne logo"
        class="img-fluid m-4"
        style="max-width: 200px;"
      />
      <br />
      <img
        src="assets/no_data.svg"
        alt="not found image"
        class="img-fluid m-4"
        style="max-width: 200px;"
      />
      <h1>
        {{ text }}
      </h1>
      <p>
        If you think this message is an error, please
        <a href="mailto:support@event1.io">contact us</a>.
      </p>
    </div>
  </div>
</div>
