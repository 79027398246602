<router-outlet></router-outlet>

<div *ngIf="showDevMode" class="dev-banner" (click)="openDevSettings(content)">
  Dev Mode
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-dev-settings-title">Dev mode settings</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('close')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="devSettings">
    <form>
      <div class="form-group">
        <label for="org">Organization Slug</label>
        <input
          id="org"
          class="form-control"
          name="org"
          [(ngModel)]="devSettings.org"
        />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('save')"
    >
      Save
    </button>
  </div>
</ng-template>
