import { SharedModule } from './shared/shared.module'
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { NotFoundComponent } from './shared/not-found/not-found.component'

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./org/org.module').then((m) => m.OrgModule)
  },
  {
    path: '_',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule, SharedModule]
})
export class AppRoutingModule {}
