import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular-ivy'
import { BrowserTracing } from '@sentry/tracing'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { config } from './app/config'

if (environment.production) {
  Sentry.init({
    dsn: 'https://cbd68eb624844844a44f61402110f0d9@o56587.ingest.sentry.io/2564371',
    release: environment.sentryRelease,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', config.apiBase],
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],
    tracesSampleRate: 1.0
  })

  enableProdMode()
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err))
})
