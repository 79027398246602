export const environment = {
  production: true,
  mapboxToken:
    'pk.eyJ1IjoibnByYWlsIiwiYSI6ImNqeWFsOXdvazBnMWwzY28zeGF1NTVzeTYifQ.oOK9cxtVv7bLmCc0AGFwqw',
  stripePublicKey: 'pk_live_OMvarHhkz9ZATC20ivJpnvI3',
  keen: {
    projectId: '5c8b01a1c9e77c000121e170',
    writeKey:
      'FBCA34BA0824AA3182F476E444ADADD49F4BCC32AA52F2C947E81D41C12F925FE6888F0EB091896E38DFA926EAC6D3C4AD5B1110218A00ED61EC0699F6967E1B7B2C2D7FF4030FCC4DC4AD653AA5B6FDE6218233776E160ACDDE31955F694CE8'
  },
  gaId: 'G-LR5ELDEWRN',
  sentryRelease: 'DEV_SENTRY_RELEASE'
}
