import { environment } from './../../environments/environment'
import { Injectable, EventEmitter } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { config } from '../config'

export interface IKeenConfig {
  projectId: string
  writeKey: string
}

export interface ISystemConfig {
  remote: boolean
  stripePublicKey: string
  environment: string
  keen: IKeenConfig
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public remoteFetched: EventEmitter<ISystemConfig> =
    new EventEmitter<ISystemConfig>()

  public config: ISystemConfig = {
    remote: false,
    stripePublicKey: environment.stripePublicKey,
    environment: environment.production ? 'production' : 'development',
    keen: environment.keen
  }

  constructor(private http: HttpClient) {
    this.getConfig()
  }

  getConfig() {
    this.http
      .get<ISystemConfig>(`${config.apiBase}/system/config`)
      .subscribe((config: ISystemConfig) => {
        config.remote = true
        this.config = config
        this.remoteFetched.emit(this.config)
      })
  }
}
